<template>
    <div class="page">
        <div class="content">
            <h1 class="h1">Eita!</h1>
            <h2 class="h2 mt8">Parece que este formulário não está disponível.</h2>
            <div class="links mt8">
                <router-link :to="{name: 'Home'}" class="link">Volte para página inicial</router-link>
                <span> ou </span>
                <router-link :to="{name: 'Signup'}" class="link">Crie o seu formulário</router-link>
            </div>
            <router-link :to="{name: 'Home'}" class="logo">
                <img src="@/assets/img/respondi-logo-gray.png" alt="Respondi Logo">
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="sass" scoped>
.page
    background: $cG1
    display: flex
    align-items: center
    justify-content: center
    height: 100vh
    position: relative
    overflow: hidden
    background-image: url('https://media.giphy.com/media/5IAoDGpfh0c1y/source.gif')
    background-size: cover

    .content
        display: flex
        background: $cWhite
        width: 90%
        max-width: 500px
        height: 350px
        text-align: center
        color: $cBlack
        flex-direction: column
        align-items: center
        justify-content: center
        border-radius: 8px
        z-index: 10
        padding: 0 $mg $mg $mg
        
        .h1, .h2
            font-weight: normal
        
        .h1
            margin-top: auto
            font-size: $fzXl * 1.4
            font-weight: bold

        .h2
            font-size: $fzSm * 1.1

        .links
            font-size: $fzXs

        .logo
            margin-top: auto
            width: 120px
            height: auto

        .link
            text-decoration: underline
            


</style>